/**
 * Handles the behavior of video players.
 */

import Rails from "@rails/ujs";
import Flash from "../flash";
import * as Util from "../util";

export default class PlayerBehavior {
  constructor(options = {}) {
    const {
      // Duration in seconds of the video
      duration,

      // Hex id of the current user watching the video
      userHexId,

      // Hex id of the lesson that is being watched
      lessonHexId,

      // Position in seconds that the video was left off at
      pickupPosition,

      // Position in seconds that indicates the video has been completed
      completionPosition,

      // An instance of a PlayerAdapter class, used to directly interact with the player
      playerAdapter,

      // Boolean indicating if this should be a 30s preview or not
      isPreview,

      // Number of seconds to show preview after
      showPreviewEndAfterSeconds,

      shouldStoreProgress,
    } = options;

    // params from options
    this.duration = duration;
    this.userHexId = userHexId;
    this.lessonHexId = lessonHexId;
    this.pickupPosition = pickupPosition;
    this.completionPosition = completionPosition;
    this.playerAdapter = playerAdapter;
    this.isPreview = isPreview;
    this.shouldStoreProgress = shouldStoreProgress;
    this.showPreviewEndAfterSeconds = showPreviewEndAfterSeconds;

    // instance vars
    this.disallowUpdatePositionRequests = false;
    this.failedUpdateCount = 0;
    this.failedUpdateDelay = 0;
    this.sentCompletionUpdate = false;
  }

  handlePlay() {
    // Set 10 second update timer on manual play event if not already set
    if (!this.sysTimeOfLastUpdate) this.sysTimeOfLastUpdate = Date.now();

    Util.focusWindow();
  }

  handleTimeUpdate(seconds) {
    if (this.shouldStoreProgress) this.storeProgress(seconds);

    if (
      this.isPreview &&
      seconds > this.showPreviewEndAfterSeconds &&
      this.onPreviewEndCallback
    ) {
      // Make sure that if we're over 30s played, we prevent from watching more
      this.onPreviewEndCallback();
    }
  }

  onPreviewEnd(callback) {
    this.onPreviewEndCallback = callback;
  }

  /**
   * Sets the player's position to the pickup position using a callback injected into
   * the class by the caller.
   */
  setPlayerPositionToPickupPosition() {
    if (!this.hasAlreadyResetPosition) {
      this.playerAdapter.setPosition(this.pickupPosition);
      this.hasAlreadyResetPosition = true;
    }
  }

  /**
   * Updates the player's position on the server.
   */
  updateProgressMarker(seconds, action) {
    if (!this.canSendUpdatePositionRequest()) return;

    if (action == "forceComplete")
      this.postProgressMarkerUpdate(this.duration, action);
    else this.postProgressMarkerUpdate(seconds, action);
  }

  /**
   * Sends the POST request that updates the player's position on the server
   */
  postProgressMarkerUpdate(seconds, action) {
    fetch("/now/progress", {
      method: "POST",
      body: JSON.stringify({
        user_hex_id: this.userHexId,
        lesson_hex_id: this.lessonHexId,
        seconds: seconds,
        event: action,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((res) => {
        if (res.ok) {
          this.failedUpdateDelay = 0;
          return;
        }

        this.handleUpdatePositionErrorResponse(res.status);
      })
      .catch((error) => {
        console.error(error);
        this.handleUpdatePositionErrorResponse(error.status);
      });
  }

  /**
   * Returns true if we are allowed to send update position requests to the server
   */
  canSendUpdatePositionRequest() {
    if (this.disallowUpdatePositionRequests) {
      return false;
    } else if (this.failedUpdateCount > 5) {
      this.disablePositionUpdateRequests();
      return false;
    } else {
      return true;
    }
  }

  /**
   * Disables sending update requests to the server & notifies user
   */
  disablePositionUpdateRequests() {
    this.disallowUpdatePositionRequests = true;
    Flash.addFlash(
      "Your progress is no longer being saved. Please refresh the page"
    );
  }

  /**
   * Handles non-200 responses that occur when sending update position requests to the server
   */
  handleUpdatePositionErrorResponse(statusCode) {
    if (statusCode === 401) {
      Util.redirectToRoot();
    } else if (statusCode === 422) {
      // In the event that the CSRF token expires, this
      // this will only send one failing request before
      // prompting the user.
      this.disablePositionUpdateRequests();
    } else {
      console.log(`hit error response status: ${statusCode}`);

      this.failedUpdateDelay = 2 ** this.failedUpdateCount * 1000;
      this.failedUpdateCount += 1;
      this.sentCompletionUpdate = false;
    }
  }

  /**
   * H
   */
  storeProgress(seconds) {
    let currentTime = Date.now();

    // Set 10 second update timer if not already set
    if (this.sysTimeOfLastUpdate === 0) this.sysTimeOfLastUpdate = currentTime;

    // True if more than 10s + delay caused by failed update requests (wall clock)
    // has elapsed since the last update.
    const hasBeenMoreThanAllowedUpdateDelay =
      this.sysTimeOfLastUpdate > 0 &&
      currentTime - this.sysTimeOfLastUpdate > 10000 + this.failedUpdateDelay;

    // True if we should send a completion event regardless of elapsed wall clock time.
    // This is still false if we have exceeded the update delay due to failed requests.
    const shouldForceSendCompletionUpdate =
      seconds >= this.completionPosition &&
      !this.sentCompletionUpdate &&
      currentTime - this.sysTimeOfLastUpdate > this.failedUpdateDelay;

    if (hasBeenMoreThanAllowedUpdateDelay) {
      this.sysTimeOfLastUpdate = currentTime;
      this.updateProgressMarker(seconds, "auto");
    } else if (shouldForceSendCompletionUpdate) {
      this.sysTimeOfLastUpdate = currentTime;
      this.sentCompletionUpdate = true;
      this.updateProgressMarker(seconds, "completion");
    }
  }
}
